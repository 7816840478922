export const img1 = require('./img1.png')
export const img2 = require('./img2.png')
export const img3 = require('./img3.png')
export const img4 = require('./img4.png')
export const img5 = require('./img5.png')
export const img6 = require('./img6.png')
export const img7 = require('./img7.png')
export const img8 = require('./img8.png')
export const img9 = require('./img9.png')
export const copy = require('./copy.png')
export const afterloom = require('./Afterloom.png')
export const v1 = require('./Vector1.png')
export const v2 = require('./Vector2.png')
export const v3 = require('./Vector3.png')
export const whatsapp = require('./whatsapp.png')
export const call = require('./call.png')
export const mail = require('./mail.png')
export const hero = require('./Hero_adp_list.png')
export const grow = require('./Groww_Dashboard_redesign.png')
export const interior = require('./interior_design.png')
export const plant = require('./plant_management_app.png')
export const invest = require('./Investment_firm_website.png')
export const home = require('./smart_home_management.png')
export const fitness = require('./bicycle_fitness_app.png')
export const design = require('./design.jpeg')
export const build = require('./build.jpeg')
export const evolution = require('./evolution.jpeg')
export const form = require('./form.png')